<template>
  <div class="w-full max-h-screen bg-background flex flex-col">
    <page-header
      title="Service Ticket Details"
      backTo="service-tickets"
      :enableLogicalBack="true"
    >
      <button
        v-if="!ticket || !ticket.is_closed"
        class="ml-4 flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        type="button"
        @click="openEditPanel"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
        </svg>
        <span>Edit Ticket</span>
      </button>
      <button
        v-if="!ticket || ticket.is_new"
        @click="setStatusToNew(false)"
        class="ml-4 flex items-center bg-indigo-200 border hover:bg-indigo-300 border-indigo-400 hover:border-indigo-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        type="button"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
          />
        </svg>
        <span>Set Under Review</span>
      </button>
      <button
        v-if="!ticket || (!ticket.is_new && !ticket.is_closed)"
        @click="setStatusToNew(true)"
        class="ml-4 flex items-center bg-indigo-200 border hover:bg-indigo-300 border-indigo-400 hover:border-indigo-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        type="button"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M15 5v2M15 11v2M15 17v2M5 5h14a2 2 0 012 2v3a2 2 0 000 4v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3a2 2 0 000-4V7a2 2 0 012-2"
          />
        </svg>
        <span>Set as New</span>
      </button>
      <button
        v-if="!ticket || !ticket.is_closed"
        class="ml-4 flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        type="button"
        @click="closeTicket()"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M18 6L6 18M6 6l12 12" />
        </svg>
        <span>Close Ticket</span>
      </button>
    </page-header>

    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div v-if="ticket">
        <div class="w-full bg-white p-5 rounded shadow-md">
          <div class="inline-flex items-center mb-4">
            <span
              v-if="ticket.is_closed"
              class="bg-green-600 text-white font-bold rounded-lg text-base px-3 py-2 leading-none uppercase mr-3"
              >Ticket Closed</span
            >
            <span
              class="rounded-lg text-base px-3 py-2 leading-none uppercase mr-3"
              :class="{
                'bg-red-200 text-red-700': getSeverity == 'High',
                'bg-yellow-200 text-yellow-700': getSeverity == 'Medium',
                'bg-green-200 text-green-700': getSeverity == 'Low',
                'bg-gray-300 text-gray-700': getSeverity == 'Normal',
              }"
              >{{ getSeverity }}</span
            >
            <h2 v-if="ticket.title" class="text-3xl space-x-2">
              <span>{{ ticket.title }}</span>
              <router-link
                :to="{
                  name: 'contacts-edit',
                  params: { contactId: ticket.contact_id },
                }"
              >
                ({{ ticket.contact_name }})
              </router-link>
            </h2>
            <router-link
              v-else
              class="text-3xl"
              :to="{
                name: 'contacts-edit',
                params: { contactId: ticket.contact_id },
              }"
              >{{ ticket.contact_name }}</router-link
            >
          </div>

          <div>
            <div class="flex items-center py-1">
              <svg
                class="text-orange-700 stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
              >
                <path d="M4 9h16M4 15h16M10 3L8 21M16 3l-2 18" />
              </svg>
              <p class="rounded-lg px-2 py-1">
                Ticket {{ ticket.ticket_number }}
              </p>
            </div>

            <div
              class="flex items-center py-1"
              v-if="
                ticket.contact_name_additional ||
                  ticket.contact_number_additional
              "
            >
              <svg
                class="text-orange-700 stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M12 6v6l4 2" />
              </svg>
              <p class="rounded-lg px-2 py-1">
                {{
                  ticket.follow_up_at
                    ? `Follow up ${$moment
                        .unix(ticket.follow_up_at)
                        .format("DD/MM/YYYY")}`
                    : "No follow up date set"
                }}
              </p>
            </div>
            <div class="flex items-center py-1">
              <svg
                class="text-orange-700 stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
              >
                <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                <circle cx="12" cy="7" r="4" />
              </svg>
              <p class="rounded-lg px-2 py-1">
                <span>
                  {{ ticket.contact_name_additional }}
                </span>
                <span v-if="ticket.contact_number_additional" class="ml-3"
                  >|</span
                >
                <a
                  v-if="ticket.contact_number_additional"
                  :href="'tel:' + contact_number_additional"
                  class="text-blue-800 hover:bg-blue-100 rounded-lg ml-1 px-2 py-1"
                  >{{ ticket.contact_number_additional }}</a
                >
                <span class="ml-1">(Additional)</span>
              </p>
            </div>
            <p class="text-gray-500 mt-3" v-if="ticket.created_by">
              Created by {{ ticket.created_by }} on
              {{
                $moment.unix(ticket.created_at).format("DD/MM/YYYY [at] HH:MM")
              }}
            </p>
          </div>
        </div>

        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <div class="flex items-center mb-5">
            <h3
              class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Description of Issue
            </h3>
            <hr class="flex-1 border-primary mx-4" />
          </div>
          <p>
            {{ ticket.description || "(No description added)" }}
          </p>
        </div>

        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <div class="flex items-center mb-5">
            <h3
              class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Affected Equipment
            </h3>
            <hr class="flex-1 border-primary mx-4" />
          </div>

          <p v-if="!ticket.equipment">
            (This ticket is not related to an equipment item)
          </p>
          <p v-if="ticket.equipment">
            {{ ticket.equipment.name }}
            <span v-if="ticket.equipment.serial_number"
              >(SN {{ ticket.equipment.serial_number }})</span
            >
          </p>
          <!-- <pre>{{ ticket.equipment }}</pre> -->
        </div>

        <div class="w-full bg-white p-5 rounded shadow-md mt-6">
          <Comments :resourceType="'ticket'" :resourceId="this.ticketId" />
        </div>
      </div>

      <div v-if="error">
        <div
          class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
        >
          <p>
            Couldn't load ticket with ID {{ this.ticketId }}:
            {{ error.statusText }}
          </p>
        </div>
      </div>

      <div v-if="viewState == 'Error'">
        <div
          class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
        >
          <p>
            Something went wrong loading the ticket, please try again. If the
            problem persists, please contact your support team.
          </p>
        </div>
      </div>

      <portal to="overlay-outlet">
        <panel
          title="Edit Service Ticket Details"
          :showing="editPanelIsOpen"
          @close="editPanelIsOpen = false"
        >
          <EditServiceTicketDetails
            :ticket="editableTicket"
            @complete="closeEditPanel"
          />
        </panel>
      </portal>

      <confirm-alert
        :showingConfirm="confirmAlert.showing"
        :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction"
      />
    </main>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Panel = () => import("@/components/Panel.vue");
const Notification = () => import("@/components/Notification.vue");
const Comments = () => import("@/components/Comments/Comments.vue");
const EditServiceTicketDetails = () =>
  import("@/components/ServiceTickets/EditServiceTicketDetail.vue");
import ConfirmAlert from "@/components/ConfirmAlert.vue";

export default {
  name: "EditServiceTicket",
  props: {
    ticketId: String,
  },
  components: {
    PageHeader,
    Panel,
    Notification,
    Comments,
    EditServiceTicketDetails,
    ConfirmAlert,
  },
  data() {
    return {
      ticket: null,
      comments: [],
      editableTicket: {},
      editPanelIsOpen: false,
      viewState: "Idle",
      error: null,
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
    };
  },
  computed: {
    getSeverity: function() {
      switch (parseInt(this.ticket.severity)) {
        case 0:
          return "Normal";
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
      }
    },
  },
  async mounted() {
    await this.loadTicket();
  },
  methods: {
    loadTicket: async function() {
      this.viewState = "Loading";

      try {
        let result = await this.ServiceTicketService.getServiceTicketById(
          this.ticketId
        );

        this.ticket = result;
        console.log(this.ticket);
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    setStatusToNew: async function(status) {
      await this.ServiceTicketService.setIsNewStatus(this.ticketId, status);
      await this.loadTicket();
    },
    closeTicket() {
      this.confirmAlert = {
        resultFunction: this.handleCloseTicket,
        message: "Are you sure you want to close this ticket? Cannot be undone",
        showing: true,
        data: this.ticket,
      };
    },
    handleCloseTicket: async function(result) {
      if (result) {
        try {
          await this.ServiceTicketService.closeTicket(this.ticketId);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Success",
                    close: onClose,
                  },
                },
                "Ticket Closed"
              );
            },
            {
              position: "top-right",
            }
          );

          this.$router.push({ name: "service-tickets" });
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem closing the ticket"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    openEditPanel() {
      this.editableTicket = { ...this.ticket };

      this.editableTicket.follow_up_at = this.ticket.follow_up_at
        ? this.$moment.unix(this.ticket.follow_up_at).toDate()
        : 0;

      this.editPanelIsOpen = true;
    },
    async closeEditPanel() {
      try {
        this.isBusy = true;

        // Lets convert to Unix format
        this.editableTicket.follow_up_at = this.editableTicket.follow_up_at
          ? this.$moment(this.editableTicket.follow_up_at).unix()
          : 0;

        let result = await this.ServiceTicketService.updateServiceTicket(
          this.ticketId,
          this.editableTicket
        );

        // Need to request again so we get the equipment if its changed
        result = await this.ServiceTicketService.getServiceTicketById(
          this.ticketId
        );

        this.ticket = result;

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Update Successful",
                  close: onClose,
                },
              },
              `Details for ticket no. ${this.ticket.ticket_number} were updated`
            );
          },
          {
            position: "top-right",
          }
        );
        this.editPanelIsOpen = false;
      } catch (err) {
        console.error(err);
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "Error occurred while updating info, please try again later"
            );
          },
          {
            position: "top-right",
          }
        );
        this.editPanelIsOpen = false;
      }

      this.isBusy = false;
    },
  },
};
</script>
